import "./styles/Navbar.css"

const Navbar = () => {
  return (
    <nav className="nav-container">
      <div className="section-container">
        <div className="nav">
          <a href="https://www.dioneprotocol.com/" className="logo logo-banner">
            <img
              src="/Logo.svg"
              alt="Odyssey Testnet"
            />
            <span>Odyssey Testnet Faucet</span>
          </a>
        </div>
      </div>
    </nav>
  )
}

export default Navbar;
