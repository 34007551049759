import FaucetForm from './components/FaucetForm'

import axios, { config } from './configure'
import './App.css'
import Navbar from "./components/Navbar";

function App() {
  return (
    <div className="app">
      <Navbar/>

      <div className="bg-shape1"/>
      <div className="bg-shape2"/>
      <div className="bg-shape3"/>
      <div className="bg-shape4"/>
      <FaucetForm axios = {axios} config = {config}/>
    </div>
  )
}

export default App
